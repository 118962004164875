import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { TextPlugin } from 'gsap/TextPlugin';

gsap.registerPlugin(ScrollTrigger, TextPlugin);

// Function to handle preloader animation
export const preloaderAnimation = () => {
  const tl = gsap.timeline({
    defaults: {
      ease: 'power3.inOut',
      duration: 1.5,
    }
  });

  tl.to('.preloader', {
    opacity: 0,
    duration: 0.5,
    onComplete: () => {
      document.querySelector('.preloader').style.display = 'none';
    }
  });

  return tl;
};

// Function to handle the initial state setup for elements
export const setInitialStates = () => {
  gsap.set('.gsap-text, .h2.fw-bold', {
    opacity: 0,
    y: 30,
    visibility: 'hidden'
  });
};

// Function to create a reusable fade-in animation
export const fadeIn = (selector, delay = 0, yOffset = 30) => {
  return gsap.fromTo(selector, 
    {
      opacity: 0,
      y: yOffset,
      visibility: 'visible',
    },
    {
      opacity: 1,
      y: 0,
      duration: 0.8,
      delay: delay,
      ease: 'power3.out'
    }
  );
};

// Master timeline to control all animations
export const masterAnimation = () => {
  const tl = gsap.timeline();

  // Preloader Animation
  tl.add(preloaderAnimation());

  // Initial Animations
  tl.add(fadeIn("h1.display-2.fw-bold", 0.2, 50));
  tl.add(fadeIn("h4.h2.fw-bold", 0.5, 30), "-=0.5");

  return tl;
};
